import { Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

const ImageViewer = ({ invoiceImages, skuImages, onClose,zoomLevel,addonSKUImage,invoiceImageName,skuImageName,addOnSkuImageName}) => {
  const viewerRef = useRef(null);
  const viewerInstance = useRef(null);
  const [activeImages, setActiveImages] = useState([]); // State to hold the images to be viewed
  const [isViewerOpen, setIsViewerOpen] = useState(false); // State to control viewer visibility



  useEffect(() => {
    if (viewerRef.current && isViewerOpen && activeImages.length > 0) {
      // Initialize Viewer.js with the activeImages
      viewerInstance.current = new Viewer(viewerRef.current, {
        inline: false, // Display in modal
        viewed() {
          viewerInstance.current.zoomTo(zoomLevel);
        },
        hidden() {
          // Only close the viewer, do not clear images
          if (onClose) onClose();
          setIsViewerOpen(false); // Hide the viewer without clearing images
        },
      });

      // Automatically open the first image in the modal
      viewerInstance.current.show();
    }

    // Cleanup the viewer instance when component unmounts or when switching images
    return () => {
      if (viewerInstance.current) {
        viewerInstance.current.destroy();
      }
    };
  }, [activeImages, isViewerOpen, onClose,zoomLevel]);

  // Programmatically close the viewer
  const closeViewer = () => {
    if (viewerInstance.current) {
      viewerInstance.current.hide(); // Close the viewer
    }
  };

  // Function to handle opening the viewer with specific images
  const openViewer = (image) => {
    setActiveImages([image]); // Set the images to view
    setIsViewerOpen(true); // Open the viewer modal
  };


  return (
    <div>
      {invoiceImages.length === 0 &&
      skuImages.length === 0 &&
      addonSKUImage.length === 0 ? (
        <p>No images found for the invoice</p>
      ) : (
        <>
          <h3>Invoice Images</h3>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "flex-start",
            }}
          >
            {invoiceImages.length > 0 ? (
              invoiceImages.map((image, index) => (
                <div key={`invoice-${index}`} style={{ textAlign: "center" }}>
                  <img
                    src={image}
                    alt={`invoice-img-${index}`}
                    style={{ width: 200, height: 200, cursor: "pointer" }}
                    onClick={() => openViewer(image)} // Open invoice images on click
                  />
                  <Typography
                    variant="subtitle2"
                    style={{ marginTop: 10, color: "#555" }}
                  >
                    {invoiceImageName[index] || "No Name Available"}
                  </Typography>
                </div>
              ))
            ) : (
              <p>No Invoice Images</p>
            )}
          </div>

          <h3>SKU Images</h3>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "flex-start",
            }}
          >
            {skuImages.length > 0 ? (
              skuImages.map((image, index) => (
                <div key={`invoice-${index}`} style={{ textAlign: "center" }}>
                  <img
                    key={`sku-${index}`}
                    src={image}
                    alt={`sku-img-${index}`}
                    style={{
                      width: 200,
                      height: 200,
                      cursor: "pointer",
                      margin: 20,
                    }}
                    onClick={() => openViewer(image)} // Open SKU images on click
                  />
                  <Typography
                    variant="subtitle2"
                    style={{ marginTop: -6, color: "#555"}}
                  >
                    {skuImageName[index] || "No Name Available"}
                  </Typography>
                </div>
              ))
            ) : (
              <p>No SKU Images</p>
            )}
          </div>

          <h3>Add On SKU Images</h3>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "flex-start",
            }}
          >
            {addonSKUImage.length > 0 ? (
              addonSKUImage.map((image, index) => (
                <div key={`invoice-${index}`} style={{ textAlign: "center" }}>
                  <img
                    key={`addon-sku-${index}`}
                    src={image}
                    alt={`addon-sku-img-${index}`}
                    style={{
                      width: 200,
                      height: 200,
                      cursor: "pointer",
                      margin: 20,
                    }}
                    onClick={() => openViewer(image)} // Open Add On SKU images on click
                  />
                  <Typography
                    variant="subtitle2"
                    style={{ marginTop: -6, color: "#555" }}
                  >
                    {addOnSkuImageName[index] || "No Name Available"}
                  </Typography>
                </div>
              ))
            ) : (
              <p>No Add On SKU Images</p>
            )}
          </div>
        </>
      )}

      {/* Hidden image elements for viewer */}
      {isViewerOpen && (
        <div ref={viewerRef} style={{ display: "none" }}>
          {/* Render only the Active Images */}
          {activeImages.map((image, index) => (
            <img key={`view-${index}`} src={image} alt={`view-img-${index}`} />
          ))}
        </div>
      )}

      {isViewerOpen && (
        <button onClick={closeViewer}>Close</button> // Button to close the viewer
      )}
    </div>
  );
};


export default ImageViewer;
