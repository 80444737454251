import { triggerSimpleAjax } from '../../helpers/httpHelper';
import {  PAN_AND_GST_VERIFICATION } from '../../helpers/constants/apiUrl';

export default function getGstAndPanDetails(req = '') {
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            PAN_AND_GST_VERIFICATION + req ,
            'POST',
            {},
            (response) => {
                res(response);
            },
            error => {
                rej(error);
            }
        );
    });
}